/* eslint-disable jsx-a11y/alt-text */
import styled from '@emotion/styled';
import { PortableText } from '@portabletext/react';
import { type SanityImageAsset, type SanityReference } from 'sanity-codegen';

import { Badge } from '@/components/common/Badge';
import { screen } from '@/components/common/breakpoints';
import { BulletPoints } from '@/components/common/bulletpoints';
import { Button } from '@/components/common/Buttons';
import { Divider } from '@/components/common/Divider';
import { Image } from '@/components/common/image';
import { Heading, HeadingLevel, Paragraph } from '@/components/common/MarkUp';
import { Surface } from '@/components/common/Surface';
import { Stack } from '@/components/layout/Stack';
import { blockUrlWithAudience } from '@/lib/utils';

import type {
  BackgroundColourTokenType,
  DecorativeColourType,
  SurfaceColourTokenType,
} from '@/types/colours';
import type { SanityAudienceType } from '@/types/sanity';
import type { BlockWithAudience, TrackingTagType } from '@/types/shared';
import type { SanityBlock, SanityKeyed } from 'sanity-codegen';

export interface CarouselCardProps extends BlockWithAudience {
  _key?: string;
  _type?: 'carouselCard';
  image?: {
    _type: 'image';
    asset: SanityReference<SanityImageAsset>;
  };
  duration?: string;
  decorativeColour?: DecorativeColourType;
  heading: string;
  body: string;
  tags?: string[];
  trackingTag?: TrackingTagType;
  secondHeading?: string;
  bulletPoints?: Array<SanityKeyed<SanityBlock>>;
  buttonAccessibleLabel?: string;
  ctaCaption?: string;
  url?: string;
  cardBackgroundColourToken: SurfaceColourTokenType | BackgroundColourTokenType;
  urlAudience?: SanityAudienceType;
  noAudienceToggle?: boolean;
}

const CarouselCard = ({
  duration,
  decorativeColour,
  heading,
  body,
  tags,
  image,
  trackingTag,
  secondHeading,
  bulletPoints,
  buttonAccessibleLabel,
  ctaCaption,
  url,
  cardBackgroundColourToken,
  urlAudience,
  pageAudience,
  noAudienceToggle,
  isAudienceSwitcherEnabled,
}: CarouselCardProps) => {
  url = blockUrlWithAudience(
    url,
    urlAudience,
    pageAudience,
    noAudienceToggle,
    isAudienceSwitcherEnabled,
  );
  return (
    <Surface
      padding={false}
      surfaceColour={cardBackgroundColourToken as SurfaceColourTokenType}
      borderRadius="--radius-l"
      className="surface"
    >
      <CustomHeightStack spacing="--spacing-x-small">
        <HeadingLevel>
          {!!image.asset && (
            <ImageContainerRow>
              <Image {...image} isDecorativeImage />
            </ImageContainerRow>
          )}
          <CardContainer>
            <Stack spacing="--spacing-x-small">
              <CopyContainer>
                <Stack spacing="--spacing-x-small">
                  {!!duration && (
                    <Badge bgColour={decorativeColour}>{duration}</Badge>
                  )}
                  <Title size="heading-md">{heading}</Title>
                  <StyledParagraph size="body-lg">{body}</StyledParagraph>
                </Stack>
              </CopyContainer>
              {tags && (
                <BadgeContainer>
                  {tags.map((tag) => {
                    return tag && <Badge>{tag}</Badge>;
                  })}
                </BadgeContainer>
              )}
              {(!!bulletPoints || !!secondHeading) && <Divider />}
              {(!!secondHeading || !!bulletPoints) && (
                <AdditionalCopyContainer>
                  <Stack spacing="--spacing-x-small">
                    {!!secondHeading && (
                      <Title size="heading-sm">{secondHeading}</Title>
                    )}
                    {!!bulletPoints && (
                      <BulletPoints
                        pointColour="--decorative-ultraviolet"
                        bulletPointStyle="check"
                      >
                        <PortableText value={bulletPoints} />
                      </BulletPoints>
                    )}
                  </Stack>
                </AdditionalCopyContainer>
              )}
            </Stack>
            {!!url && !!ctaCaption && (
              <ButtonWrapper>
                <StyledButton
                  className={
                    trackingTag?.blockName &&
                    `tracking-${trackingTag.blockName}__${trackingTag.blockAction}`
                  }
                  aria-label={buttonAccessibleLabel}
                  href={url}
                  variant="flat-light"
                >
                  {ctaCaption}
                </StyledButton>
              </ButtonWrapper>
            )}
          </CardContainer>
        </HeadingLevel>
      </CustomHeightStack>
    </Surface>
  );
};

const CustomHeightStack = styled(Stack)`
  height: 100%;
`;

const ButtonWrapper = styled.div`
  margin-top: var(--spacing-medium);
`;

const ImageContainerRow = styled.div`
  display: flex;
  height: 246px;
  flex-direction: column;
  img {
    object-fit: cover;
    border-radius: var(--radius-l) var(--radius-l) 0px 0px;
    height: 100%;
  }
`;

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: var(--radius-100);

  p:first-of-type {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  p {
    -webkit-line-clamp: 4;
  }

  ${screen.md} {
    p {
      -webkit-line-clamp: 5;
    }
  }
`;

const CardContainer = styled.div`
  padding: var(--spacing-medium);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  height: 100%;

  p:first-of-type {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  p {
    -webkit-line-clamp: 3;
  }

  ${screen.md} {
    p {
      -webkit-line-clamp: 4;
    }
  }
`;

const AdditionalCopyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-100);
`;

const StyledButton = styled(Button)`
  ${screen.md} {
    margin-bottom: 0px;
  }
  align-self: center;
  width: 100%;
  justify-content: center;
  margin-top: auto;
`;

const StyledParagraph = styled(Paragraph)`
  flex-grow: 1;
  margin-bottom: var(--spacing-150);
  color: var(--text-strong);
`;

const Title = styled(Heading)`
  color: var(--text-strong);
`;

const BadgeContainer = styled.div`
  display: flex;
  max-width: 100%;
  flex-flow: wrap;
  gap: var(--spacing-small);
  margin-top: var(--spacing-medium);
`;

export { CarouselCard };
