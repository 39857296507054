import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Badge } from '@/components/common/Badge';
import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { CarouselCard } from '@/components/common/CarouselCard';
import { HeadingLevel } from '@/components/common/MarkUp';
import { SliderV2 } from '@/components/common/SliderCommon/SliderV2';
import { ConditionalAnimateWrapper } from '@/components/common/utils';
import { Section } from '@/components/layout/Section';
import { getChildBackgroundColourToken } from '@/lib/colourUtils';
import { useMediaQuery } from '@/lib/useMediaQuery';
import { blockUrlWithAudience } from '@/lib/utils';

import type { ActionProps } from '@/components/blocks/TwoColumnLayout/ProductHighlight';
import type { AlignmentOptions } from '@/components/common/CaptionHeadingDescription';
import type { CarouselCardProps } from '@/components/common/CarouselCard';
import type {
  SanityAudienceType,
  SanityCardCarouselType,
} from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

export interface CardCarouselProps
  extends Omit<SanityCardCarouselType, 'carouselItems' | 'action'>,
    BlockWithAudience {
  carouselItems?: Array<
    Omit<CarouselCardProps, 'audienceRef'> & {
      audienceRef?: SanityAudienceType;
    }
  >;
  action?: ActionProps;
}

const CardCarousel = ({
  tag,
  heading,
  caption,
  subheading,
  headingAlignment = 'start',
  carouselItems,
  sliderTrackingTag,
  action,
  animate,
  isCaptionUppercase = false,
  backgroundColour,
  pageAudience,
  isAudienceSwitcherEnabled,
}: CardCarouselProps) => {
  const numberOfCarouselItems = carouselItems.length;
  const isDesktop = useMediaQuery(screen.smQuery);
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [slidesToScroll, setSlidesToScroll] = useState(1);
  const [slideIndex, setSlideIndex] = useState(0);
  const maxSlideIndex = Math.ceil(numberOfCarouselItems - slidesToShow);
  const canSlideRight = slideIndex < maxSlideIndex;
  const canSlideLeft = slideIndex !== 0;
  const backgroundColourToken =
    backgroundColour?.token || '--background-warm-base';

  const sliderSettings = {
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 2,
    dots: carouselItems.length > 3,
    arrows: carouselItems.length > 3,
    rows: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: carouselItems.length > 2,
          arrows: carouselItems.length > 2,
        },
      },
    ],
    beforeChange: (_previousSlideIndex, currentSlideIndex: number) => {
      setSlideIndex(currentSlideIndex);
    },
  };

  useEffect(() => {
    if (isDesktop) {
      setSlidesToShow(sliderSettings.slidesToShow);
      setSlidesToScroll(sliderSettings.slidesToScroll);
    } else {
      setSlidesToShow(sliderSettings.responsive[0].settings.slidesToShow);
    }
  }, [
    isDesktop,
    sliderSettings.responsive,
    sliderSettings.slidesToScroll,
    sliderSettings.slidesToShow,
  ]);

  const carouselCards = carouselItems.map((carouselItem) => (
    <CarouselCard
      key={carouselItem._key}
      image={carouselItem.image}
      duration={carouselItem.duration}
      decorativeColour={carouselItem.decorativeColour}
      heading={carouselItem.heading}
      body={carouselItem.body}
      tags={carouselItem.tags}
      secondHeading={carouselItem.secondHeading}
      bulletPoints={carouselItem.bulletPoints}
      ctaCaption={carouselItem.ctaCaption}
      buttonAccessibleLabel={carouselItem.buttonAccessibleLabel}
      url={carouselItem.url}
      cardBackgroundColourToken={getChildBackgroundColourToken(
        backgroundColour?.token,
      )}
      pageAudience={pageAudience}
      urlAudience={carouselItem.audienceRef}
      noAudienceToggle={carouselItem.noAudienceToggle}
      isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
    />
  ));

  const ActionButton = action?.buttonLabel && (
    <StyledButton
      href={blockUrlWithAudience(
        action?.buttonLink,
        action?.audienceRef,
        pageAudience,
        action?.noAudienceToggle,
        isAudienceSwitcherEnabled,
      )}
      aria-label={action.buttonAccessibleLabel}
      variant="flat-light"
      className={
        action.linkTrackingTag &&
        `tracking-${action.linkTrackingTag.blockName}__${action.linkTrackingTag.blockAction}`
      }
    >
      {action.buttonLabel}
    </StyledButton>
  );

  return (
    <Section
      backgroundColour={backgroundColourToken}
      verticalPadding
      className="card-carousel"
    >
      <HeadingLevel>
        <ConditionalAnimateWrapper animate={animate}>
          <ComponentWrapper>
            <>
              <DesktopTextContainer>
                {tag?.tagText && (
                  <Badge
                    bgColour={tag?.tagColour}
                    margin="0 0 var(--spacing-xxx-small) 0"
                  >
                    {tag.tagText}
                  </Badge>
                )}
                {(!!caption || !!heading || !!subheading) && (
                  <StyledCaptionHeadingDescription
                    caption={caption}
                    heading={heading}
                    description={subheading}
                    isCaptionUppercase={isCaptionUppercase}
                    desktopAlignment={headingAlignment}
                    mobileAlignment={headingAlignment}
                  />
                )}
                {!!ActionButton && (
                  <ButtonWrapper alignment={headingAlignment}>
                    {ActionButton}
                  </ButtonWrapper>
                )}
              </DesktopTextContainer>
              <SliderWrapper
                canSlideLeft={canSlideLeft}
                canSlideRight={canSlideRight}
              >
                <SliderV2
                  settings={sliderSettings}
                  heading="slider heading field"
                  slideItems={carouselItems}
                  slideItemTitleKey="heading"
                  sliderTrackingTag={sliderTrackingTag}
                  sliderPosition={Math.ceil(slideIndex / slidesToScroll)}
                  numberOfSlides={numberOfCarouselItems}
                  sliderViewports={Math.ceil(
                    numberOfCarouselItems / slidesToScroll,
                  )}
                >
                  {carouselCards}
                </SliderV2>
              </SliderWrapper>
            </>
          </ComponentWrapper>
        </ConditionalAnimateWrapper>
      </HeadingLevel>
    </Section>
  );
};

const ComponentWrapper = styled.div`
  ${screen.md} {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div<{
  alignment: AlignmentOptions;
}>`
  padding-bottom: var(--spacing-large);
  display: flex;
  justify-content: ${({ alignment }) => alignment};
`;

const StyledButton = styled(Button)``;

const StyledCaptionHeadingDescription = styled(CaptionHeadingDescription)`
  margin-bottom: var(--spacing-x-large);
`;

const DesktopTextContainer = styled.div`
  padding-right: 0;

  width: 100%;
  z-index: 1;
  position: relative;

  ${screen.md} {
    padding-right: var(--spacing-400);
  }
  ${screen.lg} {
    padding-right: var(--spacing-500);
  }
`;

const cutoffLine = (canSlide: boolean, direction: 'left' | 'right') => {
  if (canSlide && direction === 'left') {
    return css`
      ::before {
        opacity: 1;
        transition-property: opacity;
        transition-duration: 1s;
        transition-timing-function: ease-in;
      }
    `;
  }
  if (canSlide && direction === 'right') {
    return css`
      ::after {
        opacity: 1;
        transition-property: opacity;
        transition-duration: 1s;
        transition-timing-function: ease-in;
      }
    `;
  }
  return '';
};

const sharedCSS = `
  content: '';
  height: calc(100% - 100px - var(--spacing-300) + var(--spacing-xx-small));
  display: block;
  width: 1px;
  position: absolute;
  top: 0;
  background: var(--colour-border-base);
  z-index: 2;
  opacity:0;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
`;

const SliderWrapper = styled.div<{
  canSlideLeft?: boolean;
  canSlideRight?: boolean;
}>`
  display: block;
  z-index: 0;
  position: relative;
  overflow: hidden;
  padding-top: var(--spacing-xx-small);
  padding-bottom: calc(var(--spacing-large) + var(--spacing-500));
  ::after {
    ${sharedCSS}
    right: 0;
  }
  ${screen.md} {
    ::after {
      height: calc(100% - 38px - var(--spacing-xx-small));
    }
  }

  ${(props) => cutoffLine(props.canSlideLeft, 'left')}
  ${(props) => cutoffLine(props.canSlideRight, 'right')}

  width: 100%;
  padding-right: var(--spacing-600);

  > div {
    .slick-slider {
      position: static;
    }
    .slick-list {
      overflow: visible;
      height: 100%;
      position: relative;
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      position: relative;
      height: auto;
      opacity: 0.48;
      transition-property: opacity;
      transition-duration: 0.3s;
      transition-timing-function: ease-in;

      &:first-of-type {
        margin-left: 0;
      }

      > div {
        height: 100%;
        display: flex;
      }

      &.slick-active {
        opacity: 1;
      }
    }

    .slick-slide:not(:first-of-type) {
      padding-left: var(--spacing-small);
    }
  }

  .slick-arrow {
    ${screen.md} {
      background-color: transparent !important ;
    }
  }

  .slick-arrow:hover,
  .slick-arrow:focus {
    ${screen.md} {
      background-color: transparent !important;
    }
  }
`;

export { CardCarousel };
